document.addEventListener('turbolinks:load', () => {
  init_order_form();
})

function init_order_form() {
  $('.btn-number').on('click', function(e){
    e.preventDefault();
    
    fieldName = $(this).attr('data-field');
    type      = $(this).attr('data-type');
    var input = $("input[name='"+fieldName+"']");
    var currentVal = parseInt(input.val());
    if (!isNaN(currentVal)) {
      if(type == 'minus') {
          
        if(currentVal > input.attr('min')) {
            input.val(currentVal - 1).change();
        } 
        if(parseInt(input.val()) == input.attr('min')) {
            $(this).attr('disabled', true);
        }

      } else if(type == 'plus') {

        if(currentVal < input.attr('max')) {
            input.val(currentVal + 1).change();
        }
        if(parseInt(input.val()) == input.attr('max')) {
            $(this).attr('disabled', true);
        }
      }
    } else {
      input.val(0);
    }
  });
  $('#order_number_of_item').on('focusin', function(){
    $(this).data('oldValue', $(this).val());
  });
  $('#order_number_of_item').on('change', function() {
      
      minValue =  parseInt($(this).attr('min'));
      maxValue =  parseInt($(this).attr('max'));
      valueCurrent = parseInt($(this).val());
      
      n = $(this).attr('name');
      if(valueCurrent >= minValue) {
        $(".btn-number[data-type='minus'][data-field='"+n+"']").removeAttr('disabled')
      } else {
        // alert('Sorry, the minimum value was reached');
        $(this).val($(this).data('oldValue'));
      }
      if(valueCurrent <= maxValue) {
        $(".btn-number[data-type='plus'][data-field='"+n+"']").removeAttr('disabled')
      } else {
        // alert('Sorry, the maximum value was reached');
        $(this).val($(this).data('oldValue'));
      }

      $.ajax({
        url: '/orders/check_total',
        data: {
          path: String(window.location),
          number_of_item: document.getElementById('order_number_of_item').value
        }
      });
  });

  $("#order_number_of_item").on('keydown', function (e) {
      // Allow: backspace, delete, tab, escape, enter and .
      if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
          // Allow: Ctrl+A
          (e.keyCode == 65 && e.ctrlKey === true) || 
          // Allow: home, end, left, right
          (e.keyCode >= 35 && e.keyCode <= 39)) {
              // let it happen, don't do anything
              return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
          e.preventDefault();
      }
  });
}


function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
    Swal.fire({
      title: 'Success',
      html: 'Link aktivasi berhasil dicopy. Silahkan catat dan kirimkan ke teman anda. <br/><br/>Link:<br/> <div class="small text-danger text-nowrap border" style="overflow-x: scroll;">' + text + '</div>',
      icon: 'info',
      confirmButtonText: 'OK'
    })
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

window.init_order_form = init_order_form
window.fallbackCopyTextToClipboard = fallbackCopyTextToClipboard