import { PerfectScrollbar } from '../libs/perfect-scrollbar'
import { Popper } from '../libs/popper'
import { bootbox } from '../libs/bootbox'
import { SideNav } from '../javascripts/sidenav'
import { moment } from '../libs/moment'

window.PerfectScrollbar = PerfectScrollbar
window.Popper = Popper
window.bootbox = bootbox
window.SideNav = SideNav
window.moment = moment

require('../javascripts/bootstrap.js')
require('../libs/bootstrap-datepicker')
require('./layout-helpers.js')
