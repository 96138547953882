const Toastify = require('toastify-js/src/toastify')

document.addEventListener('turbolinks:load', () => {
  if (document.getElementsByClassName('toast-alert').length > 0) {
    a = document.getElementsByClassName('toast-alert')[0]
    message = a.dataset.message
    type = a.dataset.type

    if (type == 'success') {
      bg_color = " #00b09b"
    }else {
      bg_color = "#ff0000"
    }

    Toastify({
      text: message,
      duration: 100000,
      close: true,
      stopOnFocus: true,
      backgroundColor: bg_color
    }).showToast()
  }
})