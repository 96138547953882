$(document).on('turbolinks:load', function() {
  $.fn.showSidebarDetail = function() { $('.clients-wrapper').addClass('clients-sidebox-open') }
  $.fn.hideSidebarDetail = function() {  $('.clients-wrapper').removeClass('clients-sidebox-open') }

  $(document).off('click', '[data-toggle=open-detail-box]')
  $(document).on('click', '[data-toggle=open-detail-box]', function(e) {
    e.preventDefault()
    $.fn.showSidebarDetail()
    let href = $(this).data('href')
    if (href === undefined) href = $(this).attr('href')
    if (href !== undefined) {
      $.ajax({
        url: href,
        success: function(data) {
          $('[data-sidebar-target]').html(data)
        },
      })
    }
  })

  $(document).on('click', '[data-toggle=close-detail-box]', $.fn.hideSidebarDetail)
})
