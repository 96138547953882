import * as Facebook from 'fb-sdk-wrapper'

function sendTracker(obj){
  setTimeout(function() {
    $.ajax({
      url: '/main_events/' + obj.data('id') + '/shared',
      method: 'POST',
      data: {
        provider: obj.data('provider')
      },
      success: function() {
        initSocial()
      }
    })
  }, 5000)
}

function initSocial() {
  $('.facebook_share').off('click')
  $('.facebook_share').on('click', function(){
    FB.ui(
      {
        method: 'share',
        name: 'Facebook Share',
        href: $(this).data('link'),
        picture: $(this).data('picture'),
        caption: $(this).data('title'),
        description: $(this).data('description')
      },
      function(response) {
        console.log(response)
        if (response && response.post_id) {
          console.log('Post was shared.')
        } else {
          console.log('Post was not shared.')
        }
      }
    )

    sendTracker($(this))
    
  })

  $('.social_share').off('click')
  $('.social_share').on('click', function(){
    sendTracker($(this))
  })
}

Facebook.load()
  .then(() => {
    Facebook.init({
      appId: '534876447229174'
    })
  })

document.addEventListener('turbolinks:load', () => {
  initSocial()
})