import 'selectize/dist/js/selectize.min.js'; 

const selectize = (class_name = '.selectize', create = false) => {
  $(class_name).selectize({
      // create: true,
      sortField: 'text',
      create: create,
      createOnBlur: create
  });

};

export { selectize };