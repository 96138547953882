$(document).on('turbolinks:load', function() {
  // const preloadData = $('[data-program-select]').data('preload')
  // if(preloadData !== undefined) {
  //   $.each(preloadData, function(index, item){
  //     $('[data-program-select] option[value=' + item['id'] + ']').data('object', item)
  //   })
  // }

  // $(document).off('change', '[data-repopulate=program]')
  // $(document).on('change', '[data-repopulate=program]', function() {
  //   const uri = $(this).data('uri').replace(/:id/gi, $(this).val())
  //   const _that = this
  //   $('#rebate_amount').val('')
  //   $('#rebate_start_date').val('')
  //   $('#rebate_end_date').val('')
  //   $.get(uri, function(response) {
  //     buildOptions(response, $(_that).data('target'))
  //   }).fail(function() {
  //     // TODO on fails
  //   })
  // })

  // $(document).off('change', '[data-program-select]')
  // $(document).on('change', '[data-program-select]', function() {
  //   const object = $(this).find('option:selected').data('object')
  //   $('#rebate_amount').val(object['amount_float'])
  //   $('#rebate_start_date').val(object['formatted_start_time'])
  //   $('#rebate_end_date').val(object['formatted_stop_time'])
  // })

  // function buildOptions(options, idTarget) {
  //   const $target = $(idTarget)
  //   $target.html("")

  //   if (options.length > 0) {
  //     $('#no-program').addClass('d-none')
  //     $target.prop('disabled', false)
  //     if (options.length > 1) $target.append($("<option value></option>"))
  //     $.each(options, function(index, item) {
  //       if (options.length === 1) {
  //         $('#rebate_amount').val(item['amount_float'])
  //         $('#rebate_start_date').val(item['formatted_start_time'])
  //         $('#rebate_end_date').val(item['formatted_stop_time'])
  //       }
  //       $target.append($("<option value='" + item['id'] + "' data-object='" + JSON.stringify(item) + "'>" + item['name'] + "</option>"))
  //     })
  //   } else {
  //     $target.prop('disabled', true)
  //     $('#no-program').removeClass('d-none')
  //   }
  // }
})
