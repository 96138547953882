$(document).on('turbolinks:load', function() {
  // Sorting data by link lists
  $(document).off('click', '[data-toggle=sort-table]')
  $(document).on('click', '[data-toggle=sort-table]', function() {
    let value = $(this).data('value')
    let target = $(this).data('target')

    $(target).val(value)
    $(target).parents('form').submit()
  })

  $(document).off('change', '[data-toggle=change-sort-direction], [data-toggle=submit-on-change]')
  $(document).on('change', '[data-toggle=change-sort-direction], [data-toggle=submit-on-change]', function() {
    $(this).parents('form').submit()
  })

  $(document).off('click', '[data-toggle=filter-table]')
  $(document).on('click', '[data-toggle=filter-table]', function() {
    let value = $(this).data('value')
    let target = $(this).data('target')

    $(target).val(value)
    $('#filter-badge').removeClass('d-none')
    let selectValue = $('<select name="filter_value" id="filter-value" class="form-control-sm" data-toggle="submit-on-change"></select>')
    selectValue.append($('<option value=""></option>'))
    $(this).data('list').forEach(function(value, index) {
      // console.log(typeof(value) === 'string')
      if(typeof(value) === 'string') {
        selectValue.append($('<option value='+value+'>'+value+'</option>'))
      } else {
        selectValue.append($('<option value='+value[1]+'>'+value[0]+'</option>'))
      }
    })
    let htmlValue = ''
    if (value == 'city') {
      htmlValue = 'City'
    } else if (value == 'company_name' || value == 'company') {
      htmlValue = 'Company Name'
    } else if (value == 'status') {
      htmlValue = 'Status'
    } else if (value == 'product') {
      htmlValue = 'Product'
    }

    $('#filter-badge [data-filter-by]').html(htmlValue)
    $('#filter-badge [data-filter-value]').html(selectValue)
  })

  $('.custom_file').off('change');
  $('.custom_file').on('change', function(){
    var fileName = $(this).val().split("\\").pop();
    $(this).parent().siblings(".file-name").addClass("selected").html(fileName);
  });
})
