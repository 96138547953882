import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
  if (document.getElementsByClassName('pendaftar_count').length > 0) {
    var divs = document.querySelectorAll('.pendaftar_count');

    [].forEach.call(divs, function(div) {
      var id = div.dataset.id;
      consumer.subscriptions.create({channel: "EventCounterChannel", id: id}, {
        connected() {
          // Called when the subscription is ready for use on the server
          // console.log('connected ' + id)
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          // Called when there's incoming data on the websocket for this channel
          console.log('broadcasted')

          document.getElementById('user_' + data.id).innerHTML = data.user_count
          // document.getElementById('participant_' + data.id).innerHTML = data.participant_count
        }
      });
    });


  }
})
