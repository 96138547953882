// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("cocoon-js")
require("toastify")
require("direct_upload")
require("trix")
require("@rails/actiontext")
require("cleave.js")
require("tour")
require("chartjs_annotation")
// require("holderjs/holder")
require('../appwork/libs')
require("order")
require("chartkick")
import Chart from "chart.js"
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { selectize } from '../components/selectize'
import Timer from 'easytimer/src/easytimer'
import Holder from 'holderjs/holder'
import Swal from 'sweetalert2'

window.Swal = Swal

// import Swiper bundle with all modules installed

// import Swiper styles
import 'swiper/swiper-bundle.css';

require("facebook")
require("../components/floating-wpp.min")

// Include main stylesheet
require("../source/main.scss")

// Include main file
require("../source/main")

// Initialize turbolinks
require("turbolinks").start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener('turbolinks:load', () => {

  var regencies = $('#user_regency_id').html();

  $('#user_province_id').on('change', function() {
    var province, options

    province = $('#user_province_id :selected').text()

    options = $(regencies).filter("optgroup[label='" + province + "']").html();

    if (options) {
      return $('#user_regency_id').html(options);

    } else {
      return $('#user_regency_id').empty();
    }
  });

  var schools = $('#user_school_id').html();

  $('#user_regency_id').on('change', function() {
    var regency, options

    regency = $('#user_regency_id :selected').text()

    options = $(schools).filter("optgroup[label='" + regency + "']").html();

    if (options) {
      return $('#user_school_id').html(options);

    } else {
      return $('#user_school_id').empty();
    }
  });

  var study_programs = $('#user_first_study_program_id').html()

  $('#user_first_university_id').on('change', function() {
    init_program_study_1()
  });

  // var study_programs = $('#user_first_study_program_id').html()

  $('#user_second_university_id').on('change', function() {
    init_program_study_2()
  });

  function init_program_study_1(){
    var first_university, study_program_options

    first_university = $('#user_first_university_id :selected').text()

    study_program_options = $(study_programs).filter("optgroup[label='" + first_university + "']").html();

    if (study_program_options) {
      return $('#user_first_study_program_id').html(study_program_options);

    } else {
      return $('#user_first_study_program_id').empty();
    }
  }

  function init_program_study_2(){
    var second_university, study_program_options

    second_university = $('#user_second_university_id :selected').text()

    study_program_options = $(study_programs).filter("optgroup[label='" + second_university + "']").html();

    if (study_program_options) {
      return $('#user_second_study_program_id').html(study_program_options);

    } else {
      return $('#user_second_study_program_id').empty();
    }
  }

  init_program_study_1();
  init_program_study_2();


  // flag to makesure Holder.run only run once (not multiple times) on each breakpoint.
  // performance matters
  var screenBig = false;
  var screenSmall = false;
  setHolder();
  window.addEventListener('resize', setHolder);
  function setHolder() {
    if( $('.grid').length !== 0) {
      if (($(window).width() > 767.98) && (!screenBig)) {
        screenBig = true;
        screenSmall = false;
        //console.log('big'); //console.log(screenBig);
        Holder.run({
          themes: {
            "answered": {
              bg: "#02BC77",
              fg: "#fff",
              font: "Montserrat",
              size: 24
            },
            "blank": {
              bg: "#d9534f",
              fg: "#fff",
              font: "Montserrat",
              size: 24
            },
            "empty": {
              bg: "#6c757d",
              fg: "#fff",
              font: "Montserrat",
              size: 24
            },
          }
        })
      } else if (($(window).width() < 767) && (!screenSmall)) {
        screenSmall = true;
        screenBig = false;
        //console.log('small'); //console.log(screenSmall);
        Holder.run({
          themes: {
            "answered": {
              bg: "#02BC77",
              fg: "#fff",
              font: "Montserrat",
              size: 18
            },
            "blank": {
              bg: "#d9534f",
              fg: "#fff",
              font: "Montserrat",
              size: 18
            },
            "empty": {
              bg: "#6c757d",
              fg: "#fff",
              font: "Montserrat",
              size: 18
            },
          }
        })
      }
    }
  }

  var number
  selectize()
  selectize('.selectize_tag', true)

  $('#questions_fields').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    // ... do something
    selectize()
    $('.question').each(function(index, e) {
      number = index + 1
      var label = $(e).find('.question_label')[0]
      $(label).html('Soal No. ' + number)
    })
  });

  $('#content_sets_fields').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    // ... do something
    selectize()
    $('.question').each(function(index, e) {
      number = index + 1
      var label = $(e).find('.question_label')[0]
      $(label).html('Soal No. ' + number)
    })
  });


  $('#event_question_sets').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    // ... do something
    selectize()
  });

  $('.reset_answer').on('click', function(e) {
    e.preventDefault()
    $('.answer_input').prop('checked', false);
  });

  $('.answer_input').on('change', function(e){
    e.preventDefault()
    console.log($(this).val())
    // $('#answer_form').submit()
    $.ajax({
      type: "POST",
      url: $('#answer_form').attr('action'),
      data: $("#answer_form").serialize(),
      dataType: "script",
      success: function(data) {
        console.log(data);
      }
    });
  });

  if ($('.money').length > 0) {
    var cleave = new Cleave('.money', {
      numeral: true,
      numeralDecimalMark: 'thousand',
      delimiter: '.'
    });
  }

  $('#user_role').on('change', function(){
    // alert($(this).val())
    if ($(this).val() == 'Guru') {
      $('#select_classrooms').show()
      $('#select_courses').show()
    }else if ($(this).val() == 'Siswa') {
      $('#select_classrooms').show()
      $('#select_courses').hide()
    }else if ($(this).val() == 'Admin') {
      $('#select_classrooms').hide()
      $('#select_courses').hide()
    }
  })

  if ($('#countdown').length > 0) {
    if (typeof(timer) !== 'undefined') {
      timer.reset()
    }
    const timer = new Timer();
    const seconds = parseInt($('#countdown').data('remaining-seconds'))
    // console.log(seconds)
    // timer.reset()
    timer.start({countdown: true, startValues: {seconds: seconds}})
    $('#countdown .values').html(timer.getTimeValues().toString())
    timer.addEventListener('secondsUpdated', function (e) {
      $('#countdown .values').html(timer.getTimeValues().toString())
    });
    timer.addEventListener('targetAchieved', function (e) {
      $('#countdown .values').html('Waktu telah habis!')
      // alert('Waktu telah habis!')
      if ($('#answer_form').length > 0) {
        $('#timeout').val('true')
        $('#answer_form').submit()
      }
      if ($('#mulai_ujian').length > 0) {
        var url = $('#mulai_ujian').attr('href')
        window.location = url
      }
      if ($('#finish').length > 0) {
        var url = $('#finish').attr('href')
        window.location = url
      }
    });
  }

  // Dummy timer for home/ujian
  if ($('#countdown-ujian').length > 0) {
    if (typeof(timer) !== 'undefined') {
      timer.reset()
    }
    const timer = new Timer();
    const seconds = parseInt($('#countdown-ujian').data('remaining-seconds'))

    timer.start({countdown: true, startValues: {seconds: seconds}})
    $('#countdown-ujian .values-ujian').html(timer.getTimeValues().toString(['minutes', 'seconds']))
    timer.addEventListener('secondsUpdated', function (e) {
      $('#countdown-ujian .values-ujian').html(timer.getTimeValues().toString(['minutes', 'seconds']))
    });
  }

  if ($('.card_event').length > 0) {
    var timers = [];

    $('.card_event').each(function(card_event_index){
      if (typeof(timers[card_event_index]) !== 'undefined') {
        timers[card_event_index].reset()
      }
      var elem = this;
      timers[card_event_index] = new Timer();
      var seconds = parseInt($(this).data('remaining-seconds'));
  
      timers[card_event_index].start({countdown: true, startValues: {seconds: seconds}})
      
      $(elem).find('.card_event_value').html(timers[card_event_index].getTimeValues().toString())
      
      timers[card_event_index].addEventListener('secondsUpdated', function (e) {
        // $('#card_event .card_event_value').html(timer.getTimeValues().toString());
        $(elem).find('.days').html(timers[card_event_index].getTimeValues().toString(['days']));
        $(elem).find('.hours').html(timers[card_event_index].getTimeValues().toString(['hours']));
        $(elem).find('.minutes').html(timers[card_event_index].getTimeValues().toString(['minutes']));
        $(elem).find('.seconds').html(timers[card_event_index].getTimeValues().toString(['seconds']));
      });
    });
  }

  if ($('#countdown_utbk').length > 0) {
    if (typeof(utbk_timer) !== 'undefined') {
      // utbk_timer.reset()
    }
    const utbk_timer = new Timer();
    const seconds = parseInt($('#countdown_utbk').data('remaining-seconds'))

    utbk_timer.start({countdown: true, startValues: {seconds: seconds}})
    $('#countdown_utbk .card_event_value').html(utbk_timer.getTimeValues().toString())
    utbk_timer.addEventListener('secondsUpdated', function (e) {
      // $('#card_event .card_event_value').html(timer.getTimeValues().toString());
      $('#countdown_utbk .days').html(utbk_timer.getTimeValues().toString(['days']));
      $('#countdown_utbk .hours').html(utbk_timer.getTimeValues().toString(['hours']));
      $('#countdown_utbk .minutes').html(utbk_timer.getTimeValues().toString(['minutes']));
      $('#countdown_utbk .seconds').html(utbk_timer.getTimeValues().toString(['seconds']));
    });
  }

  var token = $("#snap_token").val();
  $(".order-button").on("click", function(e) {
    e.preventDefault()
    snap.pay(token, {
      onSuccess: function(res) { console.log("SUCCESS RESPONSE", res); },
      // you may also implement:
      // onPending
      // onError
    });
  });

  $('.solution_collapsible').on('click', function () {
    var text=$(this).text();
    if(text === "Lihat pembahasan"){
      $(this).html('Sembunyikan pembahasan');
    } else{
      $(this).text('Lihat pembahasan');
   }
  });

  $('.toggle-hide-number').on('click', function () {
    var text=$(this).text();
    if(text === "Sembunyikan Nomor Soal"){
      $(this).html('Lihat Nomor Soal');
    } else{
      $(this).text('Sembunyikan Nomor Soal');
   }
  });

  $('.content_collapsible').on('click', function () {
    var text=$(this).text();
    if(text === "Lihat review materi"){
      $(this).html('Sembunyikan review materi');
    } else{
      $(this).text('Lihat review materi');
   }
  });

  $('#switch_layout').off('click');
  $('#switch_layout').on('click', function() {
    if ($(this).data('collapsed')) {
      $(this).data('collapsed', false)
      $(this).find('i').removeClass('fa-chevron-right')
      $(this).find('i').addClass('fa-chevron-left')
    } else {
      $(this).data('collapsed', true)
      $(this).find('i').addClass('fa-chevron-right')
      $(this).find('i').removeClass('fa-chevron-left')
    }
    $.ajax('/switch_layout')
  });

  if ($('#analysis-chart').length > 0) {
    var myRadarChart = new Chart($('#analysis-chart'), {
      type: 'radar',
      data: $('#analysis-chart').data('chart-data'),
      options: {
        legend: {
          display: true,
          labels: {
            fontColor: 'rgb(255, 99, 132)'
          }
        },
        scale: {
            angleLines: {
              display: false
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: $('#analysis-chart').data('max-score')
            }
        }
      }
    })
  }

  // TOUR
  if ($('.soal-demo').length > 0) {
    window.tour = new Tour({
      steps: [
        {
          element: "#countdown_values",
          title: "Sisa waktu",
          description: "Ini adalah siswa waktu untuk sesi ini.",
          position: "bottom"
        },
        {
          element: ".fa-table",
          title: "Tombol navigasi soal",
          description: "Untuk menampilkan atau menyembunyikan navigasi soal.",
          position: "right"
        },
        // {
        //   element: ".sidenav-link.current",
        //   title: "Navigasi soal",
        //   description: "Posisi soal yang anda kerjakan sekarang.",
        //   position: "right"
        // },
        {
          element: ".bookmark",
          title: "Tombol bookmark",
          description: "Anda dapat menandai soal yang sedang anda kerjakan menggunakan tombol bookmark.",
          position: "left"
        },
        {
          element: "#soal",
          title: "Soal",
          description: "Baca soal dengan baik dan pilihlah jawaban yang menurut anda benar. Lalu klik tombol selanjutnya untuk menyimpan jawaban dan pindah ke soal berikutnya.",
          position: "top"
        },
        // {
        //   element: "#jawaban",
        //   title: "Pilihan jawaban",
        //   description: "Silahkan pilih jawaban yang anda anggap paling tepat",
        //   position: "top"
        // }
      ]
    })

    tour.override('showStep', function(self, step) {
      self(step);

      setTimeout(function(){
        var overlay = document.getElementsByClassName("ttour-overlay")[0];
        overlay.style.opacity = 1;
      }, 0)
    })

    tour.override('end', function(self) {
      var overlay = document.getElementsByClassName("ttour-overlay")[0];
      // var elmnt = document.getElementsById("#next")[0];
      // elmnt.scrollIntoView(false)

      overlay.style.opacity = 0;

      setTimeout(function(){
        self();
      }, 300);
    })

    tour.start();
  }

  // Toggle Form Password Value Type
  $("#show_hide_password a").on('click', function(event) {
      event.preventDefault();
      if($('#show_hide_password input').attr("type") == "text"){
          $('#show_hide_password input').attr('type', 'password');
          $('#show_hide_password i').addClass( "fa-eye-slash" );
          $('#show_hide_password i').removeClass( "fa-eye" );
      }else if($('#show_hide_password input').attr("type") == "password"){
          $('#show_hide_password input').attr('type', 'text');
          $('#show_hide_password i').removeClass( "fa-eye-slash" );
          $('#show_hide_password i').addClass( "fa-eye" );
      }
  });
})
